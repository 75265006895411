export class SortingOption {
    static readonly RELEVANCE = new SortingOption('relevance', true, 'Relevance');

    static readonly TITLE = new SortingOption('title', true, 'Title');

    static readonly DATE_AVAILABLE_ASC = new SortingOption('date_available', false, 'Date available newest first');

    static readonly DATE_AVAILABLE_DESC = new SortingOption('date_available', true, 'Date available oldest first');

    static readonly DATE_MODIFIED_ASC = new SortingOption('date_modified', false, 'Date modified newest first');

    static readonly DATE_MODIFIED_DESC = new SortingOption('date_modified', true, 'Date modified oldest first');

    static readonly VOLUME = new SortingOption('volume', false, 'Volume');

    private constructor(public readonly field: string, public readonly asc: boolean, public readonly label: string) {
        this.field = field;
        this.asc = asc;
        this.label = label;
    }

    toString() {
        return this.field;
    }

    static all(): SortingOption[] {
        return [
            this.RELEVANCE,
            this.TITLE,
            this.DATE_AVAILABLE_ASC,
            this.DATE_AVAILABLE_DESC,
            this.DATE_MODIFIED_ASC,
            this.DATE_MODIFIED_DESC,
            this.VOLUME,
        ];
    }

    static find(field: string, asc: boolean): SortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.field === field && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }
}
