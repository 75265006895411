
























import { HtmlModal, SvgImage, TwButton } from '@/app/components';
import { XCircleIcon } from '@vue-hero-icons/solid';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'AssetsUnavailableModal',
    components: { HtmlModal, TwButton, SvgImage, XCircleIcon },
});
