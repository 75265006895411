import { FeatureFlagType } from '@/app/types';

export class SearchResultType {
    static readonly API = new SearchResultType('API', 'Through an API', 'Add to query results', 'retrieve.api', true);

    static readonly KAFKA = new SearchResultType(
        'KAFKA',
        'Through a data streaming mechanism',
        'Subscribe to stream',
        'retrieve.stream',
    );

    static readonly OTHER = new SearchResultType(
        'OTHER',
        'As a downloadable file',
        'Download File(s)',
        'retrieve.files',
    );

    private constructor(
        public readonly key: string,
        public readonly name: string,
        public readonly label: string,
        public readonly feature: FeatureFlagType,
        public readonly multiple = false,
    ) {
        this.key = key;
        this.name = name;
        this.label = label;
        this.feature = feature;
        this.multiple = multiple;
    }

    toString() {
        return this.key;
    }

    allowsMultiple() {
        return this.multiple;
    }

    static all(): SearchResultType[] {
        return [this.API, this.KAFKA, this.OTHER];
    }

    static findByKey(key: string): SearchResultType | null {
        for (let i = 0; i < this.all().length; i++) {
            const searchType = this.all()[i];
            if (searchType.key === key) {
                return searchType;
            }
        }
        return null;
    }

    static findByName(name: string): SearchResultType | null {
        for (let i = 0; i < this.all().length; i++) {
            const searchType = this.all()[i];
            if (searchType.name === name) {
                return searchType;
            }
        }
        return null;
    }

    static findByLabel(label: string): SearchResultType | null {
        for (let i = 0; i < this.all().length; i++) {
            const searchType = this.all()[i];
            if (searchType.label === label) {
                return searchType;
            }
        }
        return null;
    }
}
