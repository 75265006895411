import { useAxios } from '@/app/composable';
import { computed, ref, Ref } from '@vue/composition-api';
import { RetrievalQueryAPI } from '../api';
import { RetrievalAccessibilityType, RetrievalStatus } from '../constants';
import { RetrievalQuery } from '../interfaces';

export function useRetrievalQuery(id: Ref<string>) {
    const { exec, loading } = useAxios(true);

    const retrievalQuery: Ref<RetrievalQuery | undefined> = ref<RetrievalQuery | undefined>();
    const configuration: Ref<any> = ref<any>();
    const oldConfiguration: Ref<any> = ref<any>();

    const assets: Ref<number[]> = computed(() => retrievalQuery.value?.assetIds || []);

    const accessibility: Ref<RetrievalAccessibilityType | undefined> = computed(() => {
        if (!retrievalQuery.value) return undefined;
        return RetrievalAccessibilityType.find(retrievalQuery.value?.accessibility);
    });

    const status: Ref<string | undefined> = computed(() => {
        if (!retrievalQuery.value) return undefined;
        return Object.keys(RetrievalStatus).find(
            (statusKey: string) => RetrievalStatus[statusKey] === retrievalQuery.value?.status,
        );
    });

    const statusExplanation: Ref<string | undefined> = computed(() => {
        if (!retrievalQuery.value) return undefined;
        switch (retrievalQuery.value.status) {
            case RetrievalStatus.Available:
                return 'The retrieval query is available';
            case RetrievalStatus.Configuration:
                return 'The retrieval query is still being configured';
            case RetrievalStatus.Deprecated:
                return 'The retrieval query is deprecated';
            case RetrievalStatus.Unavailable:
                return 'The retrieval query is unavailable';

            default:
                return undefined;
        }
    });

    const hasChanges = computed(() => JSON.stringify(oldConfiguration.value) !== JSON.stringify(configuration.value));

    const refetch = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            exec(RetrievalQueryAPI.get(id.value))
                .then((res: any) => {
                    configuration.value = res.data.configuration;
                    retrievalQuery.value = res.data;
                    oldConfiguration.value = res.data.configuration;
                    resolve();
                })
                .catch((error) => reject(error));
        });
    };

    const save = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (oldConfiguration.value.unavailableAssetIds?.length)
                configuration.value.datasets = configuration.value.datasets?.filter(
                    (dataset: any) => !oldConfiguration.value.unavailableAssetIds.includes(parseInt(dataset.id, 10)),
                );

            exec(
                RetrievalQueryAPI.update(id.value, {
                    configuration: configuration.value,
                    assets: assets.value,
                }),
            )
                .then((res: any) => {
                    oldConfiguration.value = res.data.configuration;
                    resolve();
                })
                .catch((error) => reject(error));
        });
    };

    return {
        retrievalQuery,
        accessibility,
        status,
        statusExplanation,
        loading,
        configuration,
        assets,
        hasChanges,
        refetch,
        save,
    };
}
