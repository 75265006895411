export class RetrievalSortingOption {
    static readonly SELECTED = new RetrievalSortingOption('selected', false, 'Selected Assets');

    static readonly TITLE_ASC = new RetrievalSortingOption('title', true, 'Title Ascending');

    static readonly TITLE_DESC = new RetrievalSortingOption('title', false, 'Title Descending');

    static readonly DATE_CREATED_DESC = new RetrievalSortingOption('date_created', false, 'Date created newest first');

    static readonly DATE_CREATED_ASC = new RetrievalSortingOption('date_created', true, 'Date created oldest first');

    static readonly DATE_UPDATED_DESC = new RetrievalSortingOption('date_updated', false, 'Date updated newest first');

    static readonly DATE_UPDATED_ASC = new RetrievalSortingOption('date_updated', true, 'Date updated oldest first');

    static readonly RELEVANCE = new RetrievalSortingOption('relevance', false, 'Relevance');

    private constructor(public readonly field: string, public readonly asc: boolean, public readonly label: string) {
        this.field = field;
        this.asc = asc;
        this.label = label;
    }

    static all(): RetrievalSortingOption[] {
        return [
            this.SELECTED,

            this.RELEVANCE,

            this.DATE_CREATED_DESC,
            this.DATE_CREATED_ASC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.TITLE_DESC,
            this.TITLE_ASC,
        ];
    }

    static find(field: string, asc: boolean): RetrievalSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (JSON.stringify(sortOption.field) === JSON.stringify(field) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string): RetrievalSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }
}
