import { useFeatureFlags } from '@/app/composable';
import {
    ApiResultsAcquisitionInformation,
    ApiResultsPreview,
    FileResultsPreview,
    FileSearchResultConfiguration,
    SearchResultConfiguration,
} from '../components';
import { RetrievalAccessibility } from './retrieval-accessibility.constants';
import { FeatureFlagType } from '@/app/types';

const { isEnabled: isFeatureEnabled } = useFeatureFlags();

export class RetrievalAccessibilityType {
    static readonly API = new RetrievalAccessibilityType(
        RetrievalAccessibility.API,
        'Through an API',
        'Expose an API to retrieval data from assets - applicable to all assets that have been mapped to a data model',
        'Add to query results',
        'retrieve.api',
        [
            { title: 'Search Results Configuration', key: 'configuration', component: () => SearchResultConfiguration },
            { title: 'Test Results Acquisition', key: 'preview', component: () => ApiResultsPreview },
            {
                title: 'Results Acquisition Information',
                key: 'retrieve',
                component: () => ApiResultsAcquisitionInformation,
            },
        ],
        true,
    );

    static readonly STREAMING = new RetrievalAccessibilityType(
        RetrievalAccessibility.STREAMING,
        'Through a data streaming mechanism',
        'Retrieve data by subscribing to a stream - applicable only to assets with active streaming jobs',
        'Subscribe to stream',
        'retrieve.stream',
        [],
    );

    static readonly FILE = new RetrievalAccessibilityType(
        RetrievalAccessibility.FILE,
        'As a downloadable file',
        'Download assets as files - applicable only to assets that have not been mapped to a data model',
        'Download File(s)',
        'retrieve.files',
        [
            {
                title: 'Search Results Configuration',
                key: 'configuration',
                component: () => FileSearchResultConfiguration,
            },
            { title: 'Test Results Acquisition', key: 'retrieve', component: () => FileResultsPreview },
        ],
    );

    private constructor(
        public readonly key: RetrievalAccessibility,
        public readonly name: string,
        public readonly description: string,
        public readonly label: string,
        public readonly feature: FeatureFlagType,
        public readonly steps: { title: string; component: any; key: string }[],
        public readonly allowsMultiple: boolean = false,
    ) {
        this.key = key;
        this.name = name;
        this.label = label;
        this.feature = feature;
        this.steps = steps;
        this.allowsMultiple = allowsMultiple;
    }

    toString() {
        return this.key;
    }

    static all(): RetrievalAccessibilityType[] {
        return [this.API, this.STREAMING, this.FILE].filter((accessibility: RetrievalAccessibilityType) =>
            isFeatureEnabled(accessibility.feature),
        );
    }

    static find(accessibility: RetrievalAccessibility): RetrievalAccessibilityType | undefined {
        return RetrievalAccessibilityType.all().find((acc: RetrievalAccessibilityType) => acc.key === accessibility);
    }
}
