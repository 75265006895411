








































































import { JsonEditor, SvgImage, TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, Ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { RetrievalAccessibilityType } from '../constants';

const sampleValues: any = {
    string: 'ENTER_VALUE',
    integer: 123,
    double: 123.4,
    datetime: '2020-02-20 00:00:00',
    date: '2020-02-20',
    time: '00:00:00+0000',
    boolean: true,
};

const sampleRanges: any = {
    integer: { min: 123, max: 456 },
    double: { min: 123.4, max: 456.7 },
    datetime: { min: '2020-02-20 00:00:00', max: '2020-02-22 23:59:59' },
    date: { min: '2020-02-20', max: '2020-02-22' },
    time: { min: '00:00:00+0000', max: '00:00:00+0000' },
};

export default defineComponent({
    name: 'ApiResultsPreview',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        uid: { type: String, required: true },
        configuration: {
            type: Object as PropType<{
                binaryConceptSelected?: boolean;
                datasets?: { fields: string[]; id: string }[];
                download?: string;
                join?: any;
                kafkaConnectionDetails?: any;
                params?: any;
            }>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                    kafkaConnectionDetails: {},
                    binaryConceptSelected: false,
                };
            },
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },

        hasChanges: {
            type: Boolean,
            default: false,
        },
    },
    components: { TwButton, OrbitSpinner, JsonEditor, SvgImage, ChevronRightIcon },
    setup(props, { root }) {
        const { exec, loading: loadingPreview, error: axiosError } = useAxios(true);
        const buildInitialQueryString = () => {
            const query = {};

            R.forEachObjIndexed((value, key) => {
                if (value.filterType === 'value') {
                    Reflect.set(query, key, sampleValues[value.fieldType]);
                } else if (value.filterType === 'range') {
                    Reflect.set(query, key, sampleRanges[value.fieldType]);
                }
            }, props.configuration.params);
            return JSON.stringify(query, null, '\t');
        };

        const response: Ref<string | null | undefined> = ref<string | null | undefined>();
        const queryString: Ref<string> = ref<string>(buildInitialQueryString());
        const error: Ref<string | undefined> = ref<string | undefined>();

        const executeQuery = async () => {
            response.value = null;
            error.value = undefined;
            let json = null;

            try {
                json = JSON.parse(queryString.value);
            } catch {
                error.value = 'Invalid JSON provided in query parameters';
            }

            if (json) {
                try {
                    const res = await exec({
                        url: `/data-retrieval/query/${props.uid}/test`,
                        method: 'POST',
                        data: json,
                    });

                    response.value = JSON.stringify(res?.data, null, '\t');
                } catch (err: any) {
                    if (err?.response?.status === 403) {
                        (root as any).$toastr.e('Error while executing query!', 'Error');
                        root.$router.push({ name: 'retrieval' });
                    }
                }
            }
        };

        const errorMessage: Ref<string | undefined> = computed(() => error.value || axiosError.value);

        return { errorMessage, response, queryString, loadingPreview, executeQuery };
    },
});
