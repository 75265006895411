import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';

export enum JoinType {
    union = 'union',
    attribute = 'attribute',
    merge = 'merge',
}

export interface Join {
    name: string;
    type: JoinType;
    description?: string;
}

export function useRetrievalAPI(datasets: Ref<any>) {
    const joins: Map<JoinType, Join> = new Map([
        [
            JoinType.attribute,
            {
                name: 'Based on shared attribute',
                type: JoinType.attribute,
                description: 'Retrieve assets by joining them with a shared attribute',
            },
        ],
        [
            JoinType.union,
            {
                name: 'Merge all records',
                type: JoinType.union,
                description: 'Retrieve assets by concatenating their records',
            },
        ],
        [
            JoinType.merge,
            {
                name: 'No join',
                type: JoinType.merge,
                description: 'Retrieve assets seperately in different objects',
            },
        ],
    ]);

    const notEmptyDatasets = computed((): any[] => {
        if (datasets.value) {
            return Object.values(datasets.value).filter((dataset: any) => !R.isEmpty(dataset.fields));
        }
        return [];
    });

    const availableJoins = computed(() => {
        const joinsAvailable: Join[] = [];
        if (notEmptyDatasets.value.length >= 2) {
            let join: any = joins.get(JoinType.merge);
            joinsAvailable.push(join);
            if (notEmptyDatasets.value.length === 2) {
                join = joins.get(JoinType.attribute);
                joinsAvailable.push(join);
            }
            const schema: any[] = notEmptyDatasets.value[0].fields;
            const sortedSchema: any = schema.sort().join(',');
            const sameStructure =
                notEmptyDatasets.value.filter((dataset: any) => sortedSchema !== dataset.fields.sort().join(','))
                    .length === 0;
            if (notEmptyDatasets.value.length >= 2 && sameStructure) {
                join = joins.get(JoinType.union);
                joinsAvailable.push(join);
            }
        }

        return joinsAvailable;
    });

    return { availableJoins };
}
