

























































































































































import { ConfirmModal } from '@/app/components';
import { useFilters } from '@/app/composable';
import { AssetTypeIcon } from '@/modules/asset/components';
import { AssetType, AssetTypeId } from '@/modules/asset/constants';
import { PencilAltIcon, TrashIcon } from '@vue-hero-icons/outline';
import { CalendarIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, defineComponent, ref } from '@vue/composition-api';
import { RetrievalAccessibility, RetrievalStatus } from '../constants';
import { RetrievalQuery } from '../interfaces';

export default defineComponent({
    name: 'RetrievalQueryCard',
    props: {
        retrievalQuery: {
            type: Object as PropType<RetrievalQuery>,
            required: true,
        },
    },
    components: { CalendarIcon, TrashIcon, PencilAltIcon, ConfirmModal, AssetTypeIcon },
    setup() {
        const { formatDateAs } = useFilters();
        const showDeleteModal: Ref<boolean> = ref<boolean>(false);

        const assetType = (typeId: number) => {
            switch (typeId) {
                case AssetTypeId.Model:
                    return AssetType.Model;
                case AssetTypeId.Result:
                    return AssetType.Result;
                default:
                    return AssetType.Dataset;
            }
        };

        const assetClass = (type: string) => {
            switch (type) {
                case AssetType.Result:
                    return 'text-blue-800';
                case AssetType.Model:
                    return 'text-cherry-700';
                default:
                    return 'text-orange-800';
            }
        };

        return {
            RetrievalStatus,
            RetrievalAccessibility,
            showDeleteModal,
            formatDateAs,
            assetType,
            assetClass,
        };
    },
});
