














































































import { TwButton } from '@/app/components';
import { ChevronRightIcon, ExclamationIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { JoinType } from '../composable';
import { RetrievalAccessibility, RetrievalAccessibilityType } from '../constants';
import { SearchResult as SearchResultInterface } from '../interfaces';
import SearchAvailableResults from './SearchAvailableResults.vue';

export default defineComponent({
    name: 'FileSearchResultConfiguration',
    components: {
        ChevronRightIcon,
        TwButton,
        SearchAvailableResults,
        ExclamationIcon,
    },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<{
                datasets?: { fields: string[]; id: string }[];
                download?: string;
                join?: any;
                params?: any;
            }>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                };
            },
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        assets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        allAssets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        totalResults: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const selectedAssetIds: Ref<number[]> = ref<number[]>([]);
        const showAssets: Ref<boolean> = ref<boolean>(true);
        const selectedAssets = computed(() =>
            props.allAssets.filter((asset: SearchResultInterface) => selectedAssetIds.value.includes(asset.id)),
        );
        const selectedDownload: Ref<string | undefined> = ref<string | undefined>();

        const retrievalQueryConfiguration: Ref<{
            datasets: { fields: string[]; id: string }[];
            download: string;
            join: {
                type?: JoinType;
                configuration?: any;
            };
            params: any;
        }> = computed(() => {
            return {
                datasets: selectedAssetIds.value.map((id: number) => {
                    return {
                        id: `${id}`,
                        fields: [],
                    };
                }),
                download: selectedDownload.value || 'direct',
                join: {},
                params: {},
            };
        });

        const processConfiguration = (newConfiguration: {
            datasets?: { fields: string[]; id: string }[];
            download?: string;
            join?: any;
            params?: any;
        }) => {
            const newAssetIds = newConfiguration.datasets
                ? newConfiguration.datasets.map((asset: { id: string; fields: string[] }) => parseInt(asset.id, 10))
                : [];

            if (JSON.stringify(newAssetIds) !== JSON.stringify(selectedAssetIds.value))
                selectedAssetIds.value = newAssetIds;

            const newDownload = newConfiguration.download ? newConfiguration.download : 'direct';
            if (newDownload !== selectedDownload.value) selectedDownload.value = newDownload;
        };

        const assetSelections = (id: string | number) => {
            const dataset = props.configuration.datasets?.find(
                (d: { id: string; fields: string[] }) => `${d.id}` === `${id}`,
            );
            return dataset ? dataset.fields : [];
        };

        const atLeastOneFileSelected = computed(() => selectedAssetIds.value.length > 0);

        watch(
            () => retrievalQueryConfiguration.value,
            (
                configuration: {
                    datasets: { fields: string[]; id: string }[];
                    download: string;
                    join: {};
                    params: {};
                },
                oldConfiguration: {
                    datasets?: { fields: string[]; id: string }[];
                    download?: string;
                    join?: {};
                    params?: {};
                },
            ) => {
                if (JSON.stringify(configuration) !== JSON.stringify(oldConfiguration)) {
                    emit('change', configuration);
                }
            },
            { deep: true },
        );

        watch(
            () => props.configuration,
            (newConfiguration: {
                datasets?: { fields: string[]; id: string }[];
                download?: string;
                join?: any;
                params?: any;
            }) => {
                processConfiguration(newConfiguration);
            },
            { immediate: true, deep: true },
        );

        return {
            selectedAssetIds,
            selectedAssets,
            showAssets,
            RetrievalAccessibility,
            atLeastOneFileSelected,
            selectedDownload,
            assetSelections,
            emit,
        };
    },
});
