import { AxiosRequestConfig } from 'axios';
import { InternalScheduleUpdate } from '../types';

const endpoint = '/api/internal-schedules';
export default {
    all: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    update: (id: string, data: InternalScheduleUpdate) =>
        ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    run: (id: string) => ({ method: 'POST', url: `${endpoint}/${id}/run` } as AxiosRequestConfig),
    enable: (id: string) => ({ method: 'POST', url: `${endpoint}/${id}/activate` } as AxiosRequestConfig),
    disable: (id: string) => ({ method: 'POST', url: `${endpoint}/${id}/deactivate` } as AxiosRequestConfig),
};
