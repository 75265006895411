import * as R from 'ramda';
import { QueryParameterTypes } from './query-parameter-types.constants';

export class QueryParameterExample {
    static readonly STRING = new QueryParameterExample(
        'string',
        new Map<QueryParameterTypes, string>().set(QueryParameterTypes.Value, 'abc'),
    );

    static readonly INTEGER = new QueryParameterExample(
        'integer',
        new Map<QueryParameterTypes, number | { min: number; max: number }>()
            .set(QueryParameterTypes.Value, 123)
            .set(QueryParameterTypes.Range, { min: 123, max: 321 }),
    );

    static readonly DOUBLE = new QueryParameterExample(
        'double',
        new Map<QueryParameterTypes, number | { min: number; max: number }>()
            .set(QueryParameterTypes.Value, 123.4)
            .set(QueryParameterTypes.Range, { min: 123.4, max: 321.0 }),
    );

    static readonly DATETIME = new QueryParameterExample(
        'datetime',
        new Map<QueryParameterTypes, string | { min: string; max: string }>()
            .set(QueryParameterTypes.Value, '2020-02-20 00:00:00')
            .set(QueryParameterTypes.Range, { min: '2020-02-20 00:00:00', max: '2020-02-22 23:59:59' }),
    );

    static readonly DATE = new QueryParameterExample(
        'date',
        new Map<QueryParameterTypes, string | { min: string; max: string }>()
            .set(QueryParameterTypes.Value, '2020-02-20')
            .set(QueryParameterTypes.Range, { min: '2020-02-20', max: '2020-02-22' }),
    );

    static readonly TIME = new QueryParameterExample(
        'time',
        new Map<QueryParameterTypes, string | { min: string; max: string }>()
            .set(QueryParameterTypes.Value, '00:00:00+0000')
            .set(QueryParameterTypes.Range, { min: '00:00:00+0000', max: '00:00:00+0000' }),
    );

    static readonly BOOLEAN = new QueryParameterExample(
        'boolean',
        new Map<QueryParameterTypes, boolean>().set(QueryParameterTypes.Value, true),
    );

    private constructor(
        private readonly typeKey: string,
        public readonly typeMapping: Map<
            QueryParameterTypes,
            string | number | boolean | { min: number; max: number } | { min: string; max: string }
        >,
    ) {
        this.typeKey = typeKey;
        this.typeMapping = typeMapping;
    }

    toString() {
        return this.typeKey;
    }

    static all() {
        return [this.STRING, this.INTEGER, this.DATETIME, this.DOUBLE, this.DATE, this.TIME, this.BOOLEAN];
    }

    private static getExample(typeKey: string, quaryParameterType: QueryParameterTypes) {
        for (let i = 0; i < this.all().length; i++) {
            const example = this.all()[i];
            if (example.typeKey === typeKey) {
                return example.typeMapping.get(quaryParameterType);
            }
        }

        // default
        return this.STRING.typeMapping.get(QueryParameterTypes.Value);
    }

    static getGetExampleParams(paramKey: string, typeKey: string, queryParameterType: QueryParameterTypes): string[] {
        const example: any = this.getExample(typeKey, queryParameterType);
        const params = [];
        if (R.is(Object, example)) {
            // adding quotes around strings only for get request
            const { min, max } = example;
            params.push(`${paramKey}_min=${min}`);
            params.push(`${paramKey}_max=${max}`);
        } else {
            params.push(`${paramKey}=${example}`);
        }
        return params;
    }

    static getPostExampleParams(paramKey: string, typeKey: string, queryParameterType: QueryParameterTypes): any {
        const example: any = this.getExample(typeKey, queryParameterType);
        const params = {};
        if (R.is(Object, example)) {
            params[paramKey] = {
                min: example.min,
                max: example.max,
            };
        } else {
            params[paramKey] = example;
        }
        return params;
    }
}
