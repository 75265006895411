



















































import { CalendarIcon, CollectionIcon, UserIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'FakeSearchResult',
    components: { CalendarIcon, CollectionIcon, UserIcon },
});
