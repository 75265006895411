import { PaginateQuery } from '@/app/interfaces';
import { AxiosRequestConfig } from 'axios';
import { ExecutionsQuery } from '../types';

const adminEndpoint = 'api/admin';
const organisationsEndpoint = 'api/organisations';
const usersEndpoint = 'api/users';
const executionConfigEndpoint = 'api/execution-configuration';

export default {
    getExecutionsById: (query: ExecutionsQuery, pagination: PaginateQuery) => {
        const params = {
            page: pagination.page,
            limit: pagination.pageSize,
        };

        if (query.facets?.type?.length) {
            params['type'] = query.facets.type;
        }

        if (query.facets?.status?.length) {
            params['status'] = query.facets.status;
        }

        if (query.sortBy) {
            params['sortBy'] = `${query.sortBy.field}:${query.sortBy.asc ? 'ASC' : 'DESC'}`;
        }

        if (query.timeTravel.fromDate) {
            params['fromDate'] = query.timeTravel.fromDate;
        }

        if (query.timeTravel.toDate) {
            params['toDate'] = query.timeTravel.toDate;
        }

        return {
            method: 'GET',
            url: `${adminEndpoint}/executions/${query.search}`,
            params,
        } as AxiosRequestConfig;
    },
    getExecutionConfiguration: (executionId: string) =>
        ({
            method: 'GET',
            url: `${executionConfigEndpoint}/execution/${executionId}`,
        } as AxiosRequestConfig),
    getOrganisations: (query: PaginateQuery, sortBy?: { field: string; asc: boolean }) => {
        const params = {
            page: query.page,
            limit: query.pageSize,
        };

        if (sortBy) {
            params['sortBy'] = `${sortBy.field}:${sortBy.asc ? 'ASC' : 'DESC'}`;
        }

        return {
            method: 'GET',
            url: `${organisationsEndpoint}`,
            params,
        } as AxiosRequestConfig;
    },
    activateOrganisation: (id: number) =>
        ({ method: 'PATCH', url: `${organisationsEndpoint}/${id}/enable` } as AxiosRequestConfig),
    deactivateOrganisation: (id: number) =>
        ({ method: 'PATCH', url: `${organisationsEndpoint}/${id}/disable` } as AxiosRequestConfig),
    getUsers: () => ({ method: 'GET', url: `${usersEndpoint}` } as AxiosRequestConfig),
    activateUser: (id: number) => ({ method: 'PATCH', url: `${usersEndpoint}/${id}/enable` } as AxiosRequestConfig),
    deactivateUser: (id: number) => ({ method: 'PATCH', url: `${usersEndpoint}/${id}/disable` } as AxiosRequestConfig),
    getStatistics: () => ({ method: 'GET', url: `${adminEndpoint}/statistics` } as AxiosRequestConfig),
    deleteOrganisation: (id: number) =>
        ({ method: 'DELETE', url: `${adminEndpoint}/organisations/${id}` } as AxiosRequestConfig),
};
