import { useQueryParams, useWindowEventListeners } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { WindowEvent } from '@/app/constants';
import { Ref, ref } from '@vue/composition-api';
import { RetrievalSortingOption } from '../constants';

export function useRetrievalSearchAssets(root: any, emit: any) {
    const router = useRouter();
    const { get, set } = useQueryParams(root, router, 'retrieval:configure');

    const defaultSortBy = RetrievalSortingOption.DATE_UPDATED_DESC.label;
    const page = ref<number>(get('page') ? parseInt(get('page'), 10) : 1);
    const sortByParam = get('sortBy') ? get('sortBy') : null;
    const sortBy = ref(sortByParam && RetrievalSortingOption.findByLabel(sortByParam) ? sortByParam : defaultSortBy);
    const query = ref({
        text: get('search') ? get('search') : '',
        settings: { caseSensitive: false, partialMatch: true },
    });

    if (sortByParam && !RetrievalSortingOption.findByLabel(sortByParam)) set('sortBy', defaultSortBy, defaultSortBy);

    const setPage = (value: number) => {
        page.value = value;
        set('page', value, 1);
    };

    const updateCurrentPage = () => {
        set('page', page.value, 1);
        emit('update-search-query', { page: page.value });
    };

    const onEnter = () => {
        set('search', query.value.text, '');
        setPage(1);
        emit('update-search-query', { page: page.value, text: query.value.text });
    };

    const sortByChanged = (label: string) => {
        if (!sortBy.value) sortBy.value = label;
        set('sortBy', label, defaultSortBy);
        const sortByObject: any = RetrievalSortingOption.findByLabel(label);
        setPage(1);
        emit('update-search-query', { page: page.value, sortBy: { field: sortByObject.field, asc: sortByObject.asc } });
    };

    const setSearchFromQueryParams = () => {
        page.value = get('page') ? parseInt(get('page'), 10) : 1;
        const sortByQueryParam = get('sortBy') ? get('sortBy') : null;
        if (sortByQueryParam)
            if (RetrievalSortingOption.findByLabel(sortByQueryParam)) sortBy.value = sortByQueryParam;
            else {
                sortBy.value = defaultSortBy;
                set('sortBy', defaultSortBy, defaultSortBy);
            }
        else sortBy.value = defaultSortBy;
        query.value.text = get('search') ? get('search') : '';
        const sortByObject: any = RetrievalSortingOption.findByLabel(sortBy.value);
        emit('update-search-query', {
            page: page.value,
            text: query.value.text,
            sortBy: { field: sortByObject.field, asc: sortByObject.asc },
        });
    };

    const clearSearch = () => {
        query.value.text = '';
        set('search', query.value.text, '');
        setPage(1);
        emit('update-search-query', { page: page.value, text: query.value.text });
    };

    const { onEvent } = useWindowEventListeners();

    const unsubscribeSearch: Ref<Function | undefined> = ref(undefined);
    // Add window event listener to handle back/forward browser buttons
    onEvent(WindowEvent.HistoryBackAndForward, setSearchFromQueryParams, (callback: any) => {
        unsubscribeSearch.value = callback;
    });

    return { page, sortBy, query, unsubscribeSearch, updateCurrentPage, onEnter, sortByChanged, clearSearch };
}
