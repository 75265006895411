
















































































import { ConfirmButton, TwSelect } from '@/app/components';
import { QueryParameterTypes } from '@/modules/search/constants';
import { ExclamationCircleIcon, PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'RetrievalQueryParameters',
    components: { TrashIcon, PlusIcon, ExclamationCircleIcon, TwSelect, ConfirmButton },
    model: {
        prop: 'selectedQueryParameters',
        event: 'change',
    },
    props: {
        selectedQueryParameters: {
            type: Object as PropType<Record<string, any>>,
            default: () => {
                return {};
            },
        },
        available: {
            type: Array as PropType<string[]>,
            default: () => {
                return [];
            },
        },
        byPath: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    setup(props, { emit }) {
        const selected = computed({
            get: () => props.selectedQueryParameters,
            set: (newSelections: Record<string, any>) => emit('change', newSelections),
        });
        const queryParameterTypes: {
            date: any;
            time: any;
            datetime: any;
            integer: any;
            string: any;
            double: any;
            boolean: any;
        } = {
            date: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            time: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            datetime: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            integer: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            double: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            boolean: [QueryParameterTypes.Value],
            string: [QueryParameterTypes.Value],
        };

        const queryParameterChange = (oldParameter: string, newQueryParameter: string, newFilterType: string) => {
            let updatedSelected = { ...selected.value };
            if (oldParameter !== newQueryParameter) {
                updatedSelected = Object.keys(updatedSelected).reduce((acc: any, paramKey: string) => {
                    if (paramKey !== oldParameter) acc[paramKey] = updatedSelected[paramKey];
                    return acc;
                }, {});
            }
            const availableFilterTypes = queryParameterTypes[props.byPath[newQueryParameter]?.type];
            updatedSelected[newQueryParameter] = {
                fieldType: props.byPath[newQueryParameter]?.type,
                filterType:
                    availableFilterTypes && availableFilterTypes.includes(newFilterType)
                        ? newFilterType
                        : availableFilterTypes[0],
            };
            selected.value = { ...updatedSelected };
        };

        const availableForDropdown = (param: string) => {
            return [param, ...props.available].map((label: string) => {
                return { label };
            });
        };

        const availableQueryParameterFiltersForDropdown = (param: string) => {
            if (!props.byPath[param]?.type) return [];
            return queryParameterTypes[props.byPath[param].type].map((label: string) => {
                return { label };
            });
        };

        const addQueryParam = () => {
            if (props.available.length > 0) {
                const newSelectedQueryParameters = { ...selected.value };
                newSelectedQueryParameters[props.available[0]] = {
                    fieldType: props.byPath[props.available[0]]?.type,
                    filterType: queryParameterTypes[props.byPath[props.available[0]].type][0],
                };
                selected.value = { ...newSelectedQueryParameters };
            }
        };

        const removeQueryParameter = (selectedKey: string) => {
            selected.value = Object.keys(selected.value).reduce((acc: any, key: string) => {
                if (key !== selectedKey) acc[key] = selected.value[key];
                return acc;
            }, {});
        };

        return {
            selected,
            availableForDropdown,
            availableQueryParameterFiltersForDropdown,
            queryParameterChange,
            addQueryParam,
            removeQueryParameter,
        };
    },
});
