import { AxiosRequestConfig } from 'axios';

const endpoint = 'api/auth';

export default {
    getUser: () => ({ method: 'GET', url: `${endpoint}/user` } as AxiosRequestConfig),
    updateUser: (data: any) => ({ method: 'PUT', url: `${endpoint}/user`, data } as AxiosRequestConfig),
    changePassword: (data: any) => ({ method: 'PUT', url: `${endpoint}/password`, data } as AxiosRequestConfig),
    getUserPreferences: () => ({ method: 'GET', url: `${endpoint}/user/preferences` } as AxiosRequestConfig),
    updateUserPreferences: (data: any) =>
        ({ method: 'PUT', url: `${endpoint}/user/preferences`, data } as AxiosRequestConfig),
};
