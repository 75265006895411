




























































































import { TwSelect } from '@/app/components';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Join, JoinType } from '../composable';

export default defineComponent({
    name: 'JoinSearchResulst',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: Object as PropType<{ type?: JoinType; configuration?: any }>,
            default: () => {
                return {};
            },
        },
        available: {
            type: Array as PropType<Join[]>,
            default: () => [],
        },
        assets: {
            type: Array as PropType<{ id: string; name: string | undefined; fields: string[]; indexed: string[] }[]>,
            default: () => [],
        },
    },
    components: { TwSelect },
    setup(props, { emit }) {
        const joinType = computed({
            get: () => props.selected.type,
            set: (newJoinType: JoinType | undefined) => {
                if (newJoinType && !joinConfiguration.value && newJoinType === JoinType.attribute) {
                    emit('change', {
                        type: JoinType.attribute,
                        configuration: {
                            localDatasetId: props.assets[0].id,
                            foreignDatasetId: props.assets[1].id,
                            localJoinAttribute: indexConcepts.value[0][0].label,
                            foreignJoinAttribute: indexConcepts.value[1][0].label,
                            overridingDataset: props.assets[0].id,
                        },
                    });
                } else if (newJoinType === JoinType.merge || newJoinType === JoinType.union) {
                    emit('change', {
                        type: newJoinType,
                    });
                } else emit('change', { type: newJoinType, configuration: joinConfiguration.value });
            },
        });
        const joinConfiguration = computed({
            get: () => props.selected.configuration,
            set: (newJoinConfiguration: any) => {
                emit('change', {
                    type: joinType.value,
                    configuration: newJoinConfiguration,
                });
            },
        });

        const indexConcepts: any = computed(() =>
            props.assets.map((asset: { id: string; name: string | undefined; fields: string[]; indexed: string[] }) =>
                asset.indexed.map((field: string) => {
                    return { label: field };
                }),
            ),
        );

        // Disable attribute join if there are no indexed concepts
        const availableJoinOptions = computed(() =>
            indexConcepts.value.filter((array: any) => array.length > 0).length !== 2
                ? props.available.filter((option: any) => option.type !== JoinType.attribute)
                : props.available,
        );

        return { joinType, joinConfiguration, indexConcepts, JoinType, availableJoinOptions };
    },
});
