import { isNil } from 'ramda';
import { ESortingOption } from '@/app/types';

export class RetrievalQueriesSortingOption extends ESortingOption {
    static readonly TITLE_ASC = new RetrievalQueriesSortingOption('title', true, 'Title Ascending');
    static readonly TITLE_DESC = new RetrievalQueriesSortingOption('title', false, 'Title Descending');
    static readonly STATUS_ASC = new RetrievalQueriesSortingOption('status', true, 'Status Ascending');
    static readonly STATUS_DESC = new RetrievalQueriesSortingOption('status', false, 'Status Descending');
    static readonly DATE_CREATED_DESC = new RetrievalQueriesSortingOption(
        'date_created',
        false,
        'Date created newest first',
    );

    static readonly DATE_CREATED_ASC = new RetrievalQueriesSortingOption(
        'date_created',
        true,
        'Date created oldest first',
    );

    static readonly DATE_UPDATED_DESC = new RetrievalQueriesSortingOption(
        'date_updated',
        false,
        'Date updated newest first',
    );

    static readonly DATE_UPDATED_ASC = new RetrievalQueriesSortingOption(
        'date_updated',
        true,
        'Date updated oldest first',
    );

    static all(): ESortingOption[] {
        return [...super.all(), ...this.local()];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        const calculatedDefault = super.calculateDefault(relevanceAvailable);
        if (!isNil(calculatedDefault)) return calculatedDefault;

        return this.DATE_UPDATED_DESC;
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        return [...super.available(relevanceAvailable), ...this.local()];
    }
    private static local(): RetrievalQueriesSortingOption[] {
        return [
            this.DATE_CREATED_DESC,
            this.DATE_CREATED_ASC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.STATUS_DESC,
            this.STATUS_ASC,

            this.TITLE_DESC,
            this.TITLE_ASC,
        ];
    }

    static find(field: string, asc: boolean): RetrievalQueriesSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (JSON.stringify(sortOption.field) === JSON.stringify(field) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string): RetrievalQueriesSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }
}
