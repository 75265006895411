import { AxiosRequestConfig } from 'axios';
import { CreateRetrievalDTO, UpdateRetrievalDTO } from '../dto';

const endpoint = '/api/retrieval';

export default {
    get: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    getURLs: (id: number, path: string) =>
        ({ method: 'GET', url: `${endpoint}/asset/${id}/urls`, params: { path } } as AxiosRequestConfig),
    create: (data: CreateRetrievalDTO) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    update: (uid: string, data: UpdateRetrievalDTO) =>
        ({ method: 'PUT', url: `${endpoint}/${uid}`, data } as AxiosRequestConfig),
    delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    sampleExecution: (uid: string, data: any) =>
        ({ method: 'POST', url: `${endpoint}/${uid}/test`, data } as AxiosRequestConfig),
};
