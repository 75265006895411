
























































































































import { Pagination, Scrollbar, SearchBox, TwSelect } from '@/app/components';
import { ChevronLeftIcon, ChevronRightIcon, FilterIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import { useRetrievalSearchAssets } from '../composable';
import { RetrievalAccessibility, RetrievalAccessibilityType, RetrievalSortingOption } from '../constants';
import { SearchResult as SearchResultInterface } from '../interfaces';
import FakeSearchResult from './FakeSearchResult.vue';
import SearchResult from './SearchResult.vue';

export default defineComponent({
    name: 'SearchAvailableResults',
    components: {
        SearchBox,
        Pagination,
        FakeSearchResult,
        SearchResult,
        Scrollbar,
        ChevronRightIcon,
        ChevronLeftIcon,
        TwSelect,
        FilterIcon,
    },
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        assets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        totalResults: {
            type: Number,
            required: true,
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const openFilters = ref(true);

        const {
            page,
            query,
            sortBy,
            unsubscribeSearch,
            sortByChanged,
            onEnter,
            clearSearch,
            updateCurrentPage,
        } = useRetrievalSearchAssets(root, emit);

        onBeforeUnmount(() => (unsubscribeSearch.value ? unsubscribeSearch.value() : undefined));

        const sortingFields = RetrievalSortingOption.all();
        const pageSize = 10;
        const availableResultsElement = ref<any>();
        const showAssets: Ref<boolean> = ref<boolean>(true);

        const selectedAssetIds = computed({
            get: () => props.selected,
            set: (updatedSelection: number[]) => {
                emit('change', updatedSelection);
            },
        });

        const add = (resultId: number) => {
            selectedAssetIds.value.push(resultId);
        };

        const remove = (idToRemove: number) => {
            selectedAssetIds.value = selectedAssetIds.value.filter((assetId: number) => assetId !== idToRemove);
        };

        return {
            page,
            query,
            sortBy,
            remove,
            add,
            selectedAssetIds,
            pageSize,
            showAssets,
            uuidv4,
            RetrievalAccessibility,
            sortingFields,
            openFilters,
            availableResultsElement,
            sortByChanged,
            onEnter,
            clearSearch,
            updateCurrentPage,
        };
    },
});
