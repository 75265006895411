import { useSearch } from '@/app/composable';
import { SearchAPI } from '@/modules/search/api';
import { computed, onBeforeUnmount, ref } from '@vue/composition-api';
import { RetrievalQueryAPI } from '../api';
import { RetrievalQueriesSortingOption } from '../constants/retrieval-queries-sorting-options.constants';
import { RetrievalQuery } from '../interfaces';

export function useRetrievalQueries(root: any, routeName: string, pageSize: number) {
    const retrievalQueries = ref<RetrievalQuery[]>([]);

    const totalResults = ref<number>(0);

    const setSearchQuery = () => {
        searchQuery.value.facets = {
            status: tab.value === 'all' ? [] : [tab.value],
        };
    };

    const search = () => {
        setCommonSearchQueryValues();
        setQueryParams();

        return new Promise((resolve, reject) => {
            exec(SearchAPI.retrievalQueries(searchQuery.value))
                .then((res: any) => {
                    retrievalQueries.value = res.data.results;
                    totalResults.value = res.data.total;
                    resolve(res.data.data);
                })
                .catch((e) => reject(e));
        });
    };

    const {
        exec,
        loading,
        error,
        page,
        sortBy,
        tab,
        query,
        searchQuery,
        setTab,
        updateCurrentPage,
        sortByChanged,
        setCommonSearchQueryValues,
        setQueryParams,
        unsubscribeSearch,
        sortingFields,
    } = useSearch(root, routeName, pageSize, RetrievalQueriesSortingOption, 'all', setSearchQuery, search);

    onBeforeUnmount(() => {
        unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
    });

    const errors = computed(() => {
        const errorsList = [];
        if (error.value) {
            errorsList.push(error.value.message);
        }

        return errorsList;
    });

    const deleteRetrievalQuery = (retrievalQuery: RetrievalQuery): Promise<void> => {
        return new Promise((resolve, reject) => {
            exec(RetrievalQueryAPI.delete(retrievalQuery.id))
                .then(async () => {
                    page.value = 1;
                    updateCurrentPage();
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    setSearchQuery();
    search();

    return {
        page,
        sortBy,
        retrievalQueries,
        errors,
        loading,
        search,
        deleteRetrievalQuery,
        query,
        tab,
        totalResults,
        updateCurrentPage,
        sortByChanged,
        setTab,
        sortingFields,
    };
}
